<template>
    <modal-lateral ref="modalHistorialProductosEliminados" :titulo="titulo">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 66px)">
            <div v-for="(r,index) in registros" :key="index" class="row mx-0 my-4">
                <div class="col-12">
                    <div class="row mx-0 px-0">
                        <div class="col-auto text-center">
                            <img :src="r.producto.foto" class="obj-cover" height="80px" width="80px" />
                        </div>
                        <div class="col d-flex flex-column justify-content-around">
                            <p class="text-general f-14 f-500 mb-1">
                                {{ r.producto.nombre }}
                            </p>
                            <p class="text-general2 f-13">
                                {{ r.producto.presentacion }}
                            </p>
                            <div class="row mx-0">
                                <p class="col text-general px-0 f-500 f-14">
                                    {{ convertMoney(r.producto.precio,idm_moneda) }}
                                </p>
                                <p v-show="tipo == 1" class="col text-general f-13">
                                    {{ separadorNumero(r.cantidad,2) }} {{ r.producto.unidad }}
                                </p>
                            </div>
                            <p class="text-general f-500 mt-1">
                                Responsable
                            </p>
                            <p class="text-general f-14 f-500">
                                {{ r.responsable }}
                            </p>
                            <p class="text-general2 f-13">
                                Motivo: {{ r.motivo }}
                            </p>
                            <p class="text-general2 f-13 f-500">
                                {{ r.created_at | helper-fecha('DD MMM YYYY hh:mm a') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    data(){
        return {
            registros:[],
            idm_moneda:0,
            tipo:0,
            titulo:'Productos eliminados del pedido'
        }
    },
    computed:{
        ...mapGetters({
            id_pedido_admin: 'pedidos/pedidos_admin/id_pedido',
            id_pedido_historial: 'pedidos/pedidos_historial_admin/id_pedido',
        }),
        id_pedido(){
            if(this.$route.name === 'admin.almacen.historial'){
                return this.id_pedido_historial
            }else{
                return this.id_pedido_admin
            }
        }
    },
    methods: {
        toggle(tipo){
            this.tipo = tipo //TIPO 1 Devolucion - 2 Eliminacion
            if(tipo === 1){
                this.titulo = 'Productos Devueltos del pedido'
            }else{
                this.titulo = 'Productos Eliminados del pedido'
            }
            this.listar_historial()
            this.$refs.modalHistorialProductosEliminados.toggle()
        },
        async listar_historial(){
            try {
                const {data} = await Pedidos.historial_eliminados_o_devueltos(this.id_pedido,this.tipo)
                this.registros = data.registros
                this.idm_moneda = data.idm_moneda
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
